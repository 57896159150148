/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// const imagesPrefix =
//   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

// First app imgs
import first_app_1 from "assets/images/apps/first_app_1.jpg";
import first_app_2 from "assets/images/apps/first_app_2.jpg";
import first_app_3 from "assets/images/apps/first_app_3.jpg";
import first_app_4 from "assets/images/apps/first_app_4.jpg";
import first_app_5 from "assets/images/apps/first_app_5.jpg";

// Second app imgs
import second_app_1 from "assets/images/apps/second_app_1.jpg";
import second_app_2 from "assets/images/apps/second_app_2.jpg";
import second_app_3 from "assets/images/apps/second_app_3.jpg";

// Third app imgs
import third_app_1 from "assets/images/apps/third_app_1.jpg";
import third_app_2 from "assets/images/apps/third_app_2.jpg";
import third_app_3 from "assets/images/apps/third_app_3.jpg";
import third_app_4 from "assets/images/apps/third_app_4.jpg";
import third_app_5 from "assets/images/apps/third_app_5.jpg";
import third_app_6 from "assets/images/apps/third_app_6.jpg";
import third_app_7 from "assets/images/apps/third_app_7.jpg";
import third_app_8 from "assets/images/apps/third_app_8.jpg";
import third_app_9 from "assets/images/apps/third_app_9.jpg";

// Fourth app imgs
import fourth_app_1 from "assets/images/apps/fourth_app_1.jpg";
import fourth_app_2 from "assets/images/apps/fourth_app_2.jpg";
import fourth_app_3 from "assets/images/apps/fourth_app_3.jpg";
import fourth_app_4 from "assets/images/apps/fourth_app_4.jpg";
import fourth_app_5 from "assets/images/apps/fourth_app_5.jpg";
import fourth_app_6 from "assets/images/apps/fourth_app_6.jpg";
import fourth_app_7 from "assets/images/apps/fourth_app_7.jpg";
import fourth_app_8 from "assets/images/apps/fourth_app_8.jpg";

export default [
  {
    title: "Motivational Daily Quotes",
    description: `Daily Quotes app delivers daily inspiration from diverse sources. Save, share, and create your own meaningful quotes with ease.`,
    items: [
      {
        image: first_app_1,
        // name: "Page Headers",
        // count: 10,
        // route: "/sections/page-sections/page-headers",
      },
      {
        image: first_app_2,
        // name: "Features",
        // count: 14,
        // route: "/sections/page-sections/features",
      },
      {
        image: first_app_3,
        // name: "Pricing",
        // count: 8,
        // pro: true,
      },
      {
        image: first_app_4,
        // name: "FAQ",
        // count: 1,
        // pro: true,
      },
      {
        image: first_app_5,
        // name: "Blog Posts",
        // count: 11,
        // pro: true,
      },
    ],
  },
  {
    title: "Keyboard Translator Pro",
    description: `Break language barriers with our Keyboard Translation App. Effortlessly communicate in multiple languages using your smartphone's keyboard.`,
    items: [
      {
        image: second_app_1,
        // name: "Navbars",
        // count: 4,
        // route: "/sections/navigation/navbars",
      },
      {
        image: second_app_2,
        // name: "Nav Tabs",
        // count: 2,
        // route: "/sections/navigation/nav-tabs",
      },
      {
        image: second_app_3,
        // name: "Pagination",
        // count: 3,
        // route: "/sections/navigation/pagination",
      },
    ],
  },
  {
    title: "PDF Translator - Doc Translate",
    description: `Translate PDFs and txt files effortlessly with PDF Translator. Choose from 50+ languages for seamless document translation, making content accessible worldwide.`,
    items: [
      {
        image: third_app_1,
        // name: "Newsletters",
        // count: 6,
        // pro: true,
      },
      {
        image: third_app_2,
        // name: "Contact Sections",
        // count: 8,
        // pro: true,
      },
      {
        image: third_app_3,
        // name: "Forms",
        // count: 3,
        // route: "/sections/input-areas/forms",
      },
      {
        image: third_app_4,
        // name: "Inputs",
        // count: 6,
        // route: "/sections/input-areas/inputs",
      },
      {
        image: third_app_5,
        // name: "Inputs",
        // count: 6,
        // route: "/sections/input-areas/inputs",
      },
      {
        image: third_app_6,
        // name: "Inputs",
        // count: 6,
        // route: "/sections/input-areas/inputs",
      },
      {
        image: third_app_7,
        // name: "Inputs",
        // count: 6,
        // route: "/sections/input-areas/inputs",
      },
      {
        image: third_app_8,
        // name: "Inputs",
        // count: 6,
        // route: "/sections/input-areas/inputs",
      },
      {
        image: third_app_9,
        // name: "Inputs",
        // count: 6,
        // route: "/sections/input-areas/inputs",
      },
    ],
  },
  {
    title: "Camera Translator - Translate",
    description: `Translate PDFs and txt files effortlessly with PDF Translator. Choose from 50+ languages for seamless document translation, making content accessible worldwide.`,
    items: [
      {
        image: fourth_app_1,
        // name: "Alerts",
        // count: 4,
        // route: "/sections/attention-catchers/alerts",
      },
      {
        image: fourth_app_2,
        // name: "Notifications",
        // count: 3,
        // pro: true,
      },
      {
        image: fourth_app_3,
        // name: "Tooltips & Popovers",
        // count: 2,
        // route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: fourth_app_4,
        // name: "Modals",
        // count: 5,
        // route: "/sections/attention-catchers/modals",
      },
      {
        image: fourth_app_5,
        // name: "Modals",
        // count: 5,
        // route: "/sections/attention-catchers/modals",
      },
      {
        image: fourth_app_6,
        // name: "Modals",
        // count: 5,
        // route: "/sections/attention-catchers/modals",
      },
      {
        image: fourth_app_7,
        // name: "Modals",
        // count: 5,
        // route: "/sections/attention-catchers/modals",
      },
      {
        image: fourth_app_8,
        // name: "Modals",
        // count: 5,
        // route: "/sections/attention-catchers/modals",
      },
    ],
  },
];
