/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={11} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            {/* <DefaultCounterCard
              count={70}
              suffix="+"
              title="APP DEVELOPMENT"
              description="From buttons, to inputs, navbars, alerts or cards, you are covered"
            /> */}
            <DefaultInfoCard
              icon="content_copy"
              title="APP DEVELOPMENT"
              description="Unlock innovation with our app development service. We turn your ideas into stunning, functional apps. Elevate your digital presence today."
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <DefaultInfoCard
              icon="content_copy"
              title="GAME DEVELOPMENT"
              description="Elevate gaming experiences with our expert game development service. From concept to launch, we craft immersive and engaging games for all platforms."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultInfoCard
              icon="content_copy"
              title="DIGITAL MARKETING"
              description="Boost your online presence with our digital marketing service. We create effective strategies to drive growth, engage audiences, and maximize ROI."
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={11} mt={3} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultInfoCard
              icon="content_copy"
              title="WEB DEVELOPMENT"
              description="Looking for a simple website or targeting a global market, the team at SoftsBots is expert in developing high-quality websites; we specialize in developing the following domain of web applications."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultInfoCard
              icon="content_copy"
              title="ACCOUNTING & BUSINESS SOFTWARES"
              description="Softsbots stands out in delivering reliable, long term business solutions to its clients. Our solution architects work in close association with clients to ensure quality and reliability of products we build."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
